<template>
  <div class="widget__wrapper" :class="{ 'widget__wrapper--landing': !isInIframe }">
    <div id="app" class="app widget" :class="{ 'widget--latest-landing': !isInIframe, 'widget-es' : !['ru', 'kz', 'by'].includes(this.$store.state.region.macroregion)}">
      <div class="widget-header" v-if="!$store.state.main.showPreload && $store.state.main.productId && $store.state.main.options.disableHeader !== true">
        <div class="widget-header__wrapper">
          <div class="widget-header__logo" v-if="['Cart', 'AddCard', 'PayFail'].includes($route.name)">
            <template v-if="['AddCard'].includes($route.name) && $store.state.main.headerLink">
              <a class="widget-header__logo" :href="$store.state.main.headerLink" target="_blank" rel="noopener noreferrer">
                <img v-if="$store.state.main.options.logoImage.substring(0, 4) !== 'http'" alt="" :src="
                    $store.state.main.apiUrl +
                    '/upload/' +
                    $store.state.main.options.code +
                    '/' +
                    $store.state.main.options.logoImage
                  " />
                <img v-else :src="$store.state.main.options.logoImage" alt="" />
              </a>
            </template>
            <template v-else>
              <template
                v-if="['AddCard'].includes($route.name) && typeof $store.state.main.options.logoImage !== 'undefined'">
                <slot name="logo">
                  <router-link class="widget-header__logo" :to="{ name: 'AddCard' }" @click.native="resetForm">
                    <img v-if="$store.state.main.options.logoImage.substring(0, 4) !== 'http'" alt="" :src="
                      $store.state.main.apiUrl +
                      '/upload/' +
                      $store.state.main.options.code +
                      '/' +
                      $store.state.main.options.logoImage
                    " />
                    <img v-else :src="$store.state.main.options.logoImage" alt="" />
                  </router-link>
                </slot>
              </template>
              <template
                v-if="['Cart', 'PayFail'].includes($route.name) && typeof $store.state.main.options.logoImage !== 'undefined'">
                <slot name="logo">
                  <router-link class="widget-header__logo" :to="{ name: 'AddCard' }">
                    <img v-if="$store.state.main.options.logoImage.substring(0, 4) !== 'http'" alt="" :src="
                      $store.state.main.apiUrl +
                      '/upload/' +
                      $store.state.main.options.code +
                      '/' +
                      $store.state.main.options.logoImage
                    " />
                    <img v-else :src="$store.state.main.options.logoImage" alt="" />
                  </router-link>
                </slot>
              </template>
            </template>
          </div>
          <div class="widget-header__back" v-if="!['Cart', 'AddCard', 'PayFail'].includes($route.name)">
            <template v-if="!['Cart', 'PayFail'].includes($route.name)">
              <a class="widget-header__back-link widget-action--btn-back" @click="$router.push({ name: 'AddCard' })">
                <span>{{ $t('app.back') }}</span>
              </a>
            </template>
            <template v-if="['AddCard'].includes($route.name)">
              <default-btn @click="$router.push({ name: 'AddCard' })" is-link-header>
                <span>{{ $t('app.back') }}</span>
              </default-btn>
            </template>
          </div>

          <div class="widget-header-controls">
            <div class="widget-header-corporate-order" v-if="$store.state.ui.corporateOrder">
              <default-btn class="btn--orange" @click="$router.push({ name: 'CorporateOrder' })">
                <span>{{ $t('corporate_order.button') }}</span>
              </default-btn>
            </div>
            <div class="widget-header-balance" v-if="$store.state.ui.checkBalance">
              <default-btn class="btn--grey" @click="openModalCheckBalance">
                <span>{{ $t('balance.title') }}</span>
              </default-btn>
            </div>


            <div class="widget-header-cart" v-if="typeof $store.state.main.options.template === 'undefined'"
              :class="{ 'widget-header-cart--grey': $store.getters['cart/quantity'] === 0 }">
              <router-link class="widget-header-cart__button widget-action--basket" :to="{ name: 'Cart' }">
                <span class="widget-header-cart__quantity">
                  <span class="widget-header-cart__icon">
                    <IconBasket />
                  </span>
                  <span class="widget-header-cart__quantity-value" v-html="$store.getters['cart/quantity']"></span>
                </span>
              </router-link>
            </div>

            <div class="widget-header-menu" @click="showHideModalMenuMobile" v-if="$store.state.main.isMobile">
              <IconMenu />
            </div>
          </div>
        </div>
      </div>

      <div class="widget__notification" v-if="isShowNotification">
        <NotifictaionWorks @close="closeNotification" />
      </div>

      <div
        class="widget__corporate-order"
        v-if="['AddCard'].includes($route.name) && $store.state.main.isMobile && $store.state.ui.corporateOrder && $store.state.main.options.disableHeader !== true">
        <span>{{ $t('corporate_order.main_page_link_1') }}</span><br>
        <span>
          <router-link
            class="widget__corporate-order-link"
            :to="{ name: 'CorporateOrder' }">
            {{ $t('corporate_order.main_page_link_2') }}
          </router-link>
        </span>
      </div>

      <div
        class="widget__body"
        :class="{
          'widget__body_loading': $store.state.main.showPreload,
          'widget__body--no-header': $store.state.main.options.disableHeader === true,
          'widget__body--no-footer': $store.state.main.options.disableFooter === true,
        }"
      >
        <router-view></router-view>
        <Loading v-if="$store.state.main.showPreload" />
      </div>

      <Footer v-if="$store.state.main.options.disableFooter !== true" />
      <Spinner />
    </div>
    <ModalMenuMobile v-if="showMobileMenu" @close-menu="showHideModalMenuMobile" />
    <ModalsContainer />
  </div>
</template>

<script>
import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import ru from './locales/ru.json'
import en from './locales/en.json'
import es from './locales/es.json'
import fr from './locales/fr.json'
import kk from './locales/kk.json'
import by from './locales/by.json'
const i18n = new VueI18n({
  messages: {
    ru, en, es, fr, kk, by
  }
})

import router from './router'
import Spinner from './components/Spinner'

import Loading from './components/Loading.vue'
import NotifictaionWorks from './components/NotifictaionWorks'
import Footer from './components/Footer'
import DefaultBtn from './components/default/DefaultBtn'
import IconBasket from './components/icons/IconBasket'
import IconMenu from './components/icons/IconMenu'
import ModalMenuMobile from './components/modals/ModalMenuMobile'
import ModalCheckBalance from './components/modals/ModalCheckBalance'
import { ModalsContainer } from 'vue-final-modal'

export default {
  i18n,
  props: ['title'],
  router,
  components: {
    Footer,
    NotifictaionWorks,
    Spinner,
    Loading,
    DefaultBtn,
    IconBasket,
    IconMenu,
    ModalMenuMobile,
    ModalsContainer
  },
  data() {
    return {
      error: '',
      showMobileMenu: false,
      isShowNotification: false,
    }
  },
  methods: {
    goTo(routeName) {
      this.$router.push({ name: routeName })
    },
    checkMobile() {
      return window.innerWidth < 760
    },
    resetForm() {
      this.$store.commit('main/setCurrentFormStep', 0)
      this.$store.commit('main/setCurrentFormStepMobile', 0)
    },
    showHideModalMenuMobile() {
      this.showMobileMenu = !this.showMobileMenu
    },
    openModalCheckBalance() {
      this.$vfm.show({
        component: ModalCheckBalance,
      })
    },
    closeNotification() {
      this.isShowNotification = false
    },
  },
  computed: {
    product() {
      if (typeof this.$store.state.main.product !== 'undefined') {
        return this.$store.state.main.product
      } else {
        return {}
      }
    },
    isInIframe() {
      try {
        return window.self !== window.top
      } catch (e) {
        return true
      }
    },
  },
  watch: {
    '$store.state.main.locale': function () {
      this.$i18n.locale = this.$store.state.main.locale
    },
  },
  created() {
    this.$i18n.locale = this.$store.state.main.locale

    if (this.checkMobile() ? !this.$store.state.main.isMobile : this.$store.state.main.isMobile) {
      this.$store.dispatch('main/setMobile', !this.$store.state.main.isMobile)
    }
    setInterval(() => {
      if (this.checkMobile() ? !this.$store.state.main.isMobile : this.$store.state.main.isMobile) {
        this.$store.dispatch('main/setMobile', !this.$store.state.main.isMobile)
      }
    }, 1000);
  },
  mounted() {
    const htmlTag = document.getElementsByTagName('html')[0];

    htmlTag.setAttribute('class', 'version-5 version-6')
  }
}
</script>

<style lang="scss" scoped>
@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-Black.eot');
	src: local('Raleway Black'), local('Raleway-Black'),
		url('assets/fonts/Raleway/Raleway-Black.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-Black.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-Black.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-ExtraLight.eot');
	src: local('Raleway ExtraLight'), local('Raleway-ExtraLight'),
		url('assets/fonts/Raleway/Raleway-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-ExtraLight.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-ExtraLight.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-Medium.eot');
	src: local('Raleway Medium'), local('Raleway-Medium'),
		url('assets/fonts/Raleway/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-Medium.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-Medium.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-LightItalic.eot');
	src: local('Raleway Light Italic'), local('Raleway-LightItalic'),
		url('assets/fonts/Raleway/Raleway-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-LightItalic.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-LightItalic.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-Regular.eot');
	src: local('Raleway'), local('Raleway-Regular'),
		url('assets/fonts/Raleway/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-Regular.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-Regular.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-Light.eot');
	src: local('Raleway Light'), local('Raleway-Light'),
		url('assets/fonts/Raleway/Raleway-Light.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-Light.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-Light.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-Thin.eot');
	src: local('Raleway Thin'), local('Raleway-Thin'),
		url('assets/fonts/Raleway/Raleway-Thin.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-Thin.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-Thin.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-ExtraBoldItalic.eot');
	src: local('Raleway ExtraBold Italic'), local('Raleway-ExtraBoldItalic'),
		url('assets/fonts/Raleway/Raleway-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-ExtraBoldItalic.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-ExtraBoldItalic.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-ExtraLightItalic.eot');
	src: local('Raleway ExtraLight Italic'), local('Raleway-ExtraLightItalic'),
		url('assets/fonts/Raleway/Raleway-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-ExtraLightItalic.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-ExtraLightItalic.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-MediumItalic.eot');
	src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'),
		url('assets/fonts/Raleway/Raleway-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-MediumItalic.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-MediumItalic.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-BoldItalic.eot');
	src: local('Raleway Bold Italic'), local('Raleway-BoldItalic'),
		url('assets/fonts/Raleway/Raleway-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-BoldItalic.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-BoldItalic.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-ThinItalic.eot');
	src: local('Raleway Thin Italic'), local('Raleway-ThinItalic'),
		url('assets/fonts/Raleway/Raleway-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-ThinItalic.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-ThinItalic.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-SemiBoldItalic.eot');
	src: local('Raleway SemiBold Italic'), local('Raleway-SemiBoldItalic'),
		url('assets/fonts/Raleway/Raleway-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-SemiBoldItalic.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-SemiBoldItalic.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-SemiBold.eot');
	src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
		url('assets/fonts/Raleway/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-SemiBold.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-SemiBold.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-Italic.eot');
	src: local('Raleway Italic'), local('Raleway-Italic'),
		url('assets/fonts/Raleway/Raleway-Italic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-Italic.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-Italic.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-Bold.eot');
	src: local('Raleway Bold'), local('Raleway-Bold'),
		url('assets/fonts/Raleway/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-Bold.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-Bold.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-ExtraBold.eot');
	src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'),
		url('assets/fonts/Raleway/Raleway-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-ExtraBold.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-ExtraBold.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('assets/fonts/Raleway/Raleway-BlackItalic.eot');
	src: local('Raleway Black Italic'), local('Raleway-BlackItalic'),
		url('assets/fonts/Raleway/Raleway-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Raleway/Raleway-BlackItalic.woff2') format('woff2'),
		url('assets/fonts/Raleway/Raleway-BlackItalic.woff') format('woff'),
		url('assets/fonts/Raleway/Raleway-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}


.app *,
.app.widget *::before,
.app.widget *::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

.app.widget article,
.app.widget aside,
.app.widget figcaption,
.app.widget figure,
.app.widget footer,
.app.widget header,
.app.widget hgroup,
.app.widget main,
.app.widget nav,
.app.widget section {
  display: block;
}


.widget__wrapper {
  min-height: 100%;
  display: flex;
  position: relative;
}

.widget__wrapper--landing {
  background: var(--theme-background);
}

.app.widget.widget-landing {
  box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.2),
    -10px 0 10px -10px rgba(0, 0, 0, 0.2);
}

.widget {
  width: 100%;
  min-width: rem(300);
  max-width: rem(860);
  min-height: 100%;
  background: var(--theme-background-widget);
  font-size: rem(20);
  font-weight: 400;
  line-height: 1.25;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  user-select: none;

  &__notification {
    padding: rem(80) rem(20) 0;
  }

  &__corporate-order {
    width: calc(100% + rem(20));
    background-color: #fff;
    font-weight: 500;
    font-size: rem(15);
    text-align: center;
    position: relative;
    left: rem(-10);
    padding: rem(16);
    margin-top: rem(60);
    margin-bottom: rem(-70);

    &-link {
      color: #FA6400;
    }
  }

  &__body {
    flex-grow: 1;
    padding: rem(100) rem(20) rem(40);
    /* padding: rem(40) rem(20) rem(40); */

    &--no-header {
      padding-top: rem(20);
    }

    &--no-footer {
      padding-bottom: rem(20);
    }

    @include mobile {
      width: 100%;
      max-width: rem(320);
      padding: rem(110) rem(10) rem(60);
      /* padding: rem(50) rem(10) rem(60); */
      margin: 0 auto;

      &--no-header {
        padding-top: rem(10);
      }

      &--no-footer {
        padding-bottom: rem(10);
      }
    }

    .gift-picture {
      width: 100%;
      height: 100%;
      min-height: 100%;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #fafafa;
      border-radius: rem(10);
      padding: rem(30) rem(20);
      margin: auto;

      @include mobile {
        width: auto;
        margin: auto;
        padding: rem(30) rem(50);
      }

      &__image {
        width: 100%;
        max-width: rem(600);

        &--default-color {
          --color-main-hue: 15;
          --color-main-saturation: 87%;
          --color-main-lightness: 58%;
        }

        img {
          width: 100%;
        }

        svg g {
          fill: hsl(var(--color-main-hue), var(--color-main-saturation), var(--color-main-lightness));
        }
      }

      &__text {
        font-weight: 300;
        font-size: rem(24);
        text-align: center;
        margin-bottom: rem(20);
      }
    }
  }
}

.widget-header {
  width: 100%;
  height: rem(60);
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;


  &__wrapper {
    width: 100%;
    min-width: rem(300);
    max-width: rem(860);
    min-height: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 rem(20);
    margin: 0 auto;
    box-shadow: 0 rem(6) rem(16) rgba(110, 120, 130, 0.1);

    @include mobile {
      padding: 0 rem(10);
    }
  }

  &__logo {
    height: rem(30);
    display: flex;
    align-items: center;

    img {
      max-height: 100%;
      max-width: 100%;
      height: 100%;

      @include mobile {
        max-width: rem(220);
      }
    }
  }

  &__back {
    height: rem(40);
    background: #F6F6F6;
    font-weight: 600;
    font-size: rem(16);
    color: #0E1E0E;
    line-height: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: rem(10);

    &:hover,
    &:active {
      background: #E9EBEC;
    }

    &-link {
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 rem(24);
    }
  }

  &__back::v-deep {
    &:hover {
      .btn {
        svg {
          opacity: 1;
        }
      }
    }
  }

  &__title {
    font-size: rem(15);
    line-height: 1;
    color: #0B132A;
  }
}

.widget-header-controls {
  display: flex;
  align-items: center;
}

.widget-header-balance,
.widget-header-corporate-order {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0 rem(5);
  @include mobile {
    display: none;
  }

  &::v-deep {
    .btn {
      padding-left: rem(16);
      padding-right: rem(16);
    }
  }
}

.widget-header-balance {
  padding-right: rem(10);
}
.widget-header-menu {
  width: rem(40);
  height: rem(40);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.widget-header-cart {
  width: rem(40);
  height: rem(40);
  background-color: #F6F6F6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: rem(10);

  &__quantity {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &-value {
      width: rem(20);
      height: rem(20);
      background: var(--theme-primary-background-component);
      font-size: rem(13);
      line-height: 100%;
      color: var(--theme-primary-text-color-component);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: rem(-9);
      bottom: 0;
      border-radius: 50%;
      padding-bottom: rem(0);
    }
  }

  &__icon::v-deep {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      opacity: 1;

      &:hover {
        opacity: 1;
      }
    }
  }

  a {
    width: 100%;
    height: 100%;
    font-size: rem(13);
    color: #0B132A;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &:hover {
    opacity: 1;
  }

  &--grey {
    pointer-events: none;
    cursor: default;
  }
}

/** {
  &::-webkit-scrollbar {
    width: 6px;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-track {
    background: #F9FAFB;
    border: 1px solid #F9FAFB;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid #FA6400;
    box-shadow: inset 0 0 2px 2px #FA6400;
  }
}*/

.widget-es::v-deep {
  .widget-input {
    &__wrapper {
      position: relative;
    }

    label {
      font-size: rem(13);
      line-height: rem(16);
      color: #666666;
      display: block;
      margin-bottom: 0;
    }

    input {
      width: 100%;
      height: rem(40);
      font-size: rem(16);
      line-height: 140%;
      color: var(--theme-primary-text-color-component);
      border: none;
      outline: none;
      border-radius: 0;
      padding: rem(6) rem(24) rem(6) 0;
      border-bottom: rem(1) solid rgb(110 120 130 / 20%);

      &::placeholder {
        color: #FF0000;
      }
    }

    &--error {
      input {
        border-bottom-color: #FF0000;
      }
    }
  }

  .widget-input-error {
    position: absolute;
    bottom: rem(6);
    right: rem(4);
    cursor: help;

    &__tip {
      max-width: rem(200);
      min-width: rem(200);
      background: var(--theme-primary-background-component);
      font-size: rem(15);
      font-weight: 400;
      line-height: 140%;
      color: #ffffff;
      text-align: center;
      position: absolute;
      left: 50%;
      bottom: rem(30);
      opacity: 0;
      border-radius: rem(4);
      padding: rem(10) rem(20);
      transform: translate(-50%, 1rem);
      transition: all .2s ease-in-out;
      pointer-events: none;

      &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 0;
        border: .5em solid transparent;
        border-top-color: var(--theme-secondary-background-component);
        transform: translate(-50%, 0);
      }
    }

    &:hover {
      .widget-input-error__tip {
        opacity: 1;
        transform: translate(-50%, 0);
      }
    }

    &--check {
      cursor: default;
    }
  }

  .widget-label-tip {
    opacity: 0;
    position: absolute;
  }

  .widget-btn {
    height: rem(40);
    font-weight: 600;
    color: var(--theme-primary-text-color-component);
    justify-content: space-between;
    padding-left: rem(24);
    padding-right: rem(24);

    @include mobile {
      height: rem(40);
      font-size: rem(15);
      padding-left: rem(24);
      padding-right: rem(24);
    }

    &__icon {
      width: rem(30);
      height: rem(30);
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-left: rem(16);
    }

    &--no-icon {
      justify-content: center;
      padding-left: rem(24);
      padding-right: rem(24);
    }

    &--full-width {
      width: 100%;
    }

    &::v-deep {
      svg {
        opacity: 0.6;
      }

      &:active,
      &:hover {
        svg {
          opacity: 1;

          @include mobile {
            opacity: 0.6;
          }
        }
      }

      &:active {
        @include mobile {
          svg {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
