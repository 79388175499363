import Vue from 'vue'
import { orderBy, findIndex } from 'lodash'
import { isSupportedStorage } from '../../modules/utils'

const params = Object.fromEntries(new URLSearchParams(location.search))
const stateKey = params.productId || params.code || params.key

let initialState = {
  positions: [],
  promoCode: null,
  paymentCode: null,
}

if (isSupportedStorage(() => localStorage)) {
  if (window.localStorage['widget_state_' + stateKey]) {
    const stateStorage = JSON.parse(window.localStorage['widget_state_' + stateKey])
    const cart = stateStorage.cart
    const isValidCart = cart !== '{}' && cart.positions.length > 0

    cart.positions = cart.positions.filter((position) => {
      return position &&
            position.cardImage &&
            position.face &&
            position.id &&
            position.productId &&
            position.quantity &&
            position.recipientEmail
    })

    if (stateStorage && isValidCart) {
      initialState = cart
    }
  }
}

const module = {
  namespaced: true,
  state: initialState,
  getters: {
    positions (state) {
      return orderBy(state.positions, ['id'], ['asc'])
    },
    positionsGroupedBySender (state) {
      let groups = {}
      const positions = orderBy(state.positions, ['id'], ['asc'])
      positions.forEach(position => {
        let key
        if (position.senderEmail === null) {
          key = 'myself'
        } else {
          key = position.senderName + '|' + position.senderEmail
        }
        if (typeof groups[key] === 'undefined') {
          groups[key] = {
            name: position.senderName,
            email: position.senderEmail,
            positions: []
          }
        }
        groups[key].positions.push(position)
      })
      return groups
    },
    positionsGroupedBySenderAndImage (state) {
      let groups = {}
      const positions = orderBy(state.positions, ['id'], ['asc'])

      positions.forEach(position => {
        let key

        if (position.senderEmail === null) {
          key = 'myself'
        } else {
          key = position.senderName + '|' + position.senderEmail+ '|' + position.cardImage
        }

        if (typeof groups[key] === 'undefined') {
          groups[key] = {
            image: position.cardImage,
            group: {
              name: position.senderName,
              email: position.senderEmail,
              positions: []
            }
          }
        }
        groups[key].group.positions.push(position)

      })

      return groups
    },
    sum: state => {
      if (state.positions.length) {
        return state.positions.reduce((sum, item) => sum + item.face * item.quantity, 0)
      } else {
        return 0
      }
    },
    quantity: state => {
      if (typeof state !== 'undefined' && state.positions.length) {
        return state.positions.reduce((quantity, item) => quantity + parseInt(item.quantity), 0)
      } else {
        return 0
      }
    }
  },
  mutations: {
    editPosition (state, payload) {
      const index = findIndex(state.positions, { id: payload.id })
      const date = new Date()

      if (index < 0) {
        state.positions.push({ ...payload, id: date.getTime().toString() })
      } else {
        state.positions.splice(index, 1, payload)
      }
    },
    removePosition (state, id) {
      const index = findIndex(state.positions, { id: id })
      if (index > -1) {
        state.positions.splice(index, 1)
      }
    },
    setQuantity (state, payload) {
      const index = findIndex(state.positions, { id: payload.id })
      state.positions[index].quantity = payload.quantity
    },
    setPositions (state, payload) {
      state.position = payload
    },
    setPromoCode (state, payload) {
      state.promoCode = payload
    },
    setPaymentCode (state, payload) {
      state.paymentCode = payload
    },
    clear: (state) => {
      state.positions = []
    }
  },
  actions: {
    addPosition: ({ commit }, payload) => {
      Vue.$logger.info('cart | addPosition: ', JSON.parse(JSON.stringify(payload)))
      commit('editPosition', payload)
    },
    removePosition: ({ commit }, payload) => {
      Vue.$logger.info('cart | removePosition: ', JSON.parse(JSON.stringify(payload)))
      commit('removePosition', payload)
    },
    setPositions: ({ commit }, payload) => {
      Vue.$logger.info('cart | setPositions: ', JSON.parse(JSON.stringify(payload)))
      commit('setPositions', payload)
    },
    setPromoCode: ({ commit }, payload) => {
      Vue.$logger.info('cart | setPromoCode: ', JSON.parse(JSON.stringify(payload)))
      commit('setPromoCode', payload)
    },
    setPaymentCode: ({ commit }, payload) => {
      Vue.$logger.info('cart | setPaymentCode: ', JSON.parse(JSON.stringify(payload)))
      commit('setPaymentCode', payload)
    },
    clear ({ commit }) {
      Vue.$logger.debug('cart | Clear')
      commit('clear')
    }
  }
}

export default module
