<template>
  <div class="modal-mobile-menu">
    <div class="modal-mobile-menu__wrapper">
      <div class="modal-mobile-menu__header">
        <div class="modal-mobile-menu__header-wrapper">
          <div class="modal-mobile-menu__logo" v-if="['Cart', 'AddCard', 'PayFail'].includes($route.name)">
            <template
              v-if="['AddCard'].includes($route.name) && typeof $store.state.main.options.logoImage !== 'undefined'">
              <slot name="logo">
                <router-link class="modal-mobile-menu__logo" :to="{ name: 'AddCard' }" @click.native="resetForm">
                  <img v-if="$store.state.main.options.logoImage.substring(0, 4) !== 'http'" alt="" :src="
                    $store.state.main.apiUrl +
                    '/upload/' +
                    $store.state.main.options.code +
                    '/' +
                    $store.state.main.options.logoImage
                  " />
                  <img v-else :src="$store.state.main.options.logoImage" alt="" />
                </router-link>
              </slot>
            </template>
            <template
              v-if="['Cart', 'PayFail'].includes($route.name) && typeof $store.state.main.options.logoImage !== 'undefined'">
              <slot name="logo">
                <router-link class="modal-mobile-menu__logo" :to="{ name: 'AddCard' }">
                  <img v-if="$store.state.main.options.logoImage.substring(0, 4) !== 'http'" alt="" :src="
                    $store.state.main.apiUrl +
                    '/upload/' +
                    $store.state.main.options.code +
                    '/' +
                    $store.state.main.options.logoImage
                  " />
                  <img v-else :src="$store.state.main.options.logoImage" alt="" />
                </router-link>
              </slot>
            </template>
          </div>
          <div class="modal-mobile-menu__close" @click="onClose()">
            <IconClose />
          </div>
        </div>
      </div>

      <div class="modal-mobile-menu__content">
        <div class="modal-mobile-menu-footer" v-if="!$store.state.main.showPreload && $store.state.main.productId">
          <div class="modal-mobile-menu-footer__top">
            <div class="modal-mobile-menu-footer__row">
              <div class="modal-mobile-menu__btns">
                <div
                  v-if="$store.state.ui.corporateOrder"
                  class="modal-mobile-menu-goto"
                  @click="goTo('CorporateOrder')">
                  <span class="modal-mobile-menu-goto__text">{{ $t('corporate_order.button') }}</span>
                  <span class="modal-mobile-menu-goto__arrow"><IconArrow /></span>
                </div>
                <div
                  v-if="$store.state.ui.checkBalance"
                  class="modal-mobile-menu-goto"
                  @click="openModalCheckBalance()">
                  <span class="modal-mobile-menu-goto__text">{{ $t('mobile_menu.balance') }}</span>
                  <span class="modal-mobile-menu-goto__arrow"><IconArrow /></span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-mobile-menu-footer__bottom">
            <div class="modal-mobile-menu-footer__row">
              <div class="modal-mobile-menu-footer-giftery-info">
                <div class="modal-mobile-menu-footer-giftery-info__logo" v-if="!($store.state.main.options.showCopyrightLogo === false)">
                  <template v-if="!['ru', 'kz', 'by'].includes(this.$store.state.region.macroregion)">
                    <div>
                      <LogoGifteryEs />
                    </div>
                  </template>
                  <template v-else>
                    <div>
                      <LogoGiftery />
                    </div>
                    <div v-if="product.region === 'by'">
                      <div class="modal-mobile-menu-footer-giftery-info__logo-separator"></div>
                    </div>
                    <div>
                      <LogoGifteryBelarus v-if="product.region === 'by'" />
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <div class="modal-mobile-menu-footer__row">
              <div class="modal-mobile-menu-footer-list">
                <div class="modal-mobile-menu-footer-list__title">{{ $t('app.info') }}</div>
                <div class="modal-mobile-menu-footer-list__items">
                  <div class="modal-mobile-menu-footer-list__item">
                    <span v-if="!($store.state.main.options.showHelp === false)" @click="goTo('Help')">
                      {{ $t('app.how_it_works') }}
                    </span>
                  </div>
                  <slot name="conditionsLink" v-if="!($store.state.main.options.showConditions === false)">
                    <div class="modal-mobile-menu-footer-list__item">
                      <span @click="goTo('Conditions')">
                        {{ $t('app.terms_and_coditions') }}
                      </span>
                    </div>
                  </slot>
                  <slot name="offerLink" v-if="!($store.state.main.options.showOfferLink === false)">
                    <div class="modal-mobile-menu-footer-list__item" @click="goTo('Offer')">
                      <span @click="goTo('Offer')">
                        {{ $t('app.agreement') }}
                      </span>
                    </div>
                  </slot>
                </div>
              </div>
            </div>
            <template v-if="!($store.state.main.options.showFooterContacts === false)">
              <div class="modal-mobile-menu-footer__row" v-if="product.region !== 'es'">
                <div class="modal-mobile-menu-footer-list">
                  <div class="modal-mobile-menu-footer-list__title">
                    {{ getLocaleTitle() }}
                  </div>
                  <div class="modal-mobile-menu-footer-list__items">
                    <template v-if="!['kz', 'by'].includes(this.$store.state.region.macroregion)">
                      <div class="modal-mobile-menu-footer-list__item" v-if="!($store.state.main.options.showCopyrightPhone === false)">
                        <a :href="getLocalePhoneLink()">
                          <span v-html="getLocalePhone()"></span>
                        </a>
                      </div>
                    </template>
                    <template v-else>
                      <div class="modal-mobile-menu-footer-list__item" v-if="!($store.state.main.options.showCopyrightPhone === false)">
                        <a href="https://t.me/GifteryHelp_bot" target="_blank">@GifteryHelp_bot</a>
                      </div>
                    </template>
                    <div v-if="getLocaleEmail()" class="modal-mobile-menu-footer-list__item">
                      <a :href="getLocaleEmailLink()">
                        <span v-html="getLocaleEmail()"></span>
                      </a>
                    </div>
                    <div v-if="getLocaleTime()" class="modal-mobile-menu-footer-list__item">
                      <span v-html="getLocaleTime()"></span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>

        </div>

        <div class="modal-mobile-menu__bottom">
          <div class="modal-mobile-menu__btns">
            <default-btn @click="goTo('Cart')">
              {{ $t('mobile_menu.cart') }}
            </default-btn>
          </div>

          <div class="modal-mobile-menu__copyright">
            <div class="modal-mobile-menu-footer-giftery-info__copyright" v-if="!($store.state.main.options.showCopyright === false)">
              ©{{ (new Date()).getFullYear() }} Giftery. {{ $t('app.copyright') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultBtn from '../../components/default/DefaultBtn'
import LogoGiftery from '../../components/icons/LogoGiftery'
import LogoGifteryEs from '../../components/icons/LogoGifteryEs'
import LogoGifteryBelarus from '../../components/icons/LogoGifteryBelarus'
import IconClose from '../../components/icons/IconClose'
import ModalCheckBalance from '../../components/modals/ModalCheckBalance'
import IconArrow from '../icons/IconArrow'

export default {
  name: 'ModalMenuMobile',
  components: {
    DefaultBtn,
    LogoGiftery,
    LogoGifteryEs,
    LogoGifteryBelarus,
    IconClose,
    IconArrow,
  },
  data: () => ({}),
  computed: {
    product() {
      if (typeof this.$store.state.main.product === 'undefined') {
        return {}
      } else {
        return this.$store.state.main.product
      }
    },
    currentLocale() {
      return this.$store.state.main.locale
    },
  },
  methods: {
    onClose() {
      this.$emit('close-menu')
    },
    goTo(routeName) {
      this.onClose()
      this.$router.push({ name: routeName })
    },
    openModalCheckBalance() {
      this.$vfm.show({
        component: ModalCheckBalance,
      })
    },
    region() {
      return this.product.region
    },
    getLocaleTitle() {
      return this.$t(`app.contacts.${this.region()}.title`, this.currentLocale)
    },
    getLocaleTime() {
      return this.$t(`app.contacts.${this.region()}.time`, this.currentLocale)
    },
    getLocaleEmail() {
      return this.$t(`app.contacts.${this.region()}.email`, this.currentLocale)
    },
    getLocaleEmailLink() {
      return 'mailto:' + this.$t(`app.contacts.${this.region()}.email`, this.currentLocale)
    },
    getLocalePhone() {
      return this.$t(`app.contacts.${this.region()}.phone`, this.currentLocale)
    },
    getLocalePhoneLink() {
      const phone = this.$t(`app.contacts.${this.region()}.phone`, this.currentLocale)

      return 'tel:' + phone.replace(/ |\(|\)/g, '')
    },
  }
};
</script>

<style lang="scss" scoped>
.modal-mobile-menu {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  overflow-y: scroll;

  &__wrapper {
    height: 100%;
    position: relative;
  }

  &__header {
    width: 100%;
    height: rem(60);
    position: absolute;
    top: 0;
    left: 0;

    &-wrapper {
      height: rem(40);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: rem(10);
    }
  }

  &__logo {
    height: rem(30);
    display: flex;
    align-items: center;

    img {
      max-height: 100%;
      max-width: 100%;
      height: 100%;

      @include mobile {
        max-width: rem(220);
      }
    }
  }

  &__close {
    width: rem(24);
    height: rem(24);
    cursor: pointer;

    &::v-deep {
      svg {
        pointer-events: none;
      }
    }
  }

  &__bottom {
    margin-bottom: rem(10);
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 rem(10);
  }

  &__btns {
    width: 100%;
    padding-bottom: rem(40);

    &::v-deep {
      .btn {
        width: 100%;
        margin-bottom: rem(10);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.modal-mobile-menu-goto {
  height: rem(44);
  background-color: #F6F6F6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 rem(10);
  border-radius: rem(10);
  margin-bottom: rem(4);
  cursor: pointer;

  &__text{
    font-weight: 500;
    font-size: rem(15);
    color: #0B132A;
  }

  &__arrow {
    width: rem(20);
    height: rem(20);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal-mobile-menu-footer {
  height: 100%;
  background-color: #ffffff;
  font-size: rem(13);
  line-height: 120%;
  color: #666666;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: rem(40) 0 0;
  margin-top: rem(60);

  &__row {
    /* &:nth-child(1) {
      order: 3;
      background-color: transparent;
      border: none;
      border-radius: 0;
      padding: rem(10) 0 0;
      margin-bottom: 0;
    }

    &:nth-child(2) {
      @include mobile {
        order: 1;
      }
    }

    &:nth-child(3) {
      @include mobile {
        order: 2;
      }
    }

    &:last-child {
      padding-left: rem(10);

      @include mobile {
        padding-left: 0;
      }
    } */
  }
}

.modal-mobile-menu-footer-giftery-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mobile {
    flex-direction: row;
    align-items: center;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(20);

    @include mobile {
      display: flex;
      align-items: center;
      flex-direction: column;
      display: none;
      margin-bottom: 0;
    }

    &>div {
      display: flex;
      align-items: center;
    }

    &-separator {
      width: rem(80);
      height: rem(2);
      background-color: #fb7720;
      display: flex;
      margin: rem(6) 0;
    }
  }

  &__copyright {
    font-size: rem(12);
    line-height: 160%;
    text-align: center;
    margin-top: rem(20);
  }

  &::v-deep {
    svg {
      width: rem(80);
      height: rem(30);
    }
  }
}

.modal-mobile-menu-footer-list {
  &__title {
    font-size: rem(12);
    color: #6E7882;
    margin-bottom: rem(20);
  }

  &__items {
    margin-bottom: rem(40);
  }

  &__item {
    font-weight: 600;
    font-size: rem(15);
    color: #0B132A;
    line-height: 120%;
    display: flex;
    margin-bottom: rem(10);

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }

    span {
      cursor: pointer;

      &:hover {
        color: #0B132A;
        text-decoration: underline;
      }
    }

    a {
      color: #0B132A;
      text-decoration: none;

      &:hover {
        color: #0B132A;
      }
    }
  }
}

</style>
