<template>
  <span>С кэшбеком 5% при оплате картой от Альфа Банка</span>
</template>

<script>
export default {
  name: 'Title25765',
  props: {
    fill: {
      type: String,
      default: '#0E1E0E',
    },
  },
}
</script>

<style lang="scss" scoped>
// span {
//   text-transform: uppercase;
// }
</style>
